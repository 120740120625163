<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    Header
    ModalPage(
      v-if="uid"
      :showModal="showModalPage"
      :headerRightIcon="headerRightIcon"
      :modalPageContentId="modalPageContentId"
      @headerOnLeft="headerOnLeft"
      @closeModalPage="closeModalPage")
    div.wrap-inbox
      ModuleInbox(v-if="uid")

</template>

<style lang="scss" scoped>
.wrap-inbox {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import Header from '@/components/shared/Header'
import ModalPage from '@/components/shared/ModalPage.vue'
import ModuleInbox from '@/components/module/ModuleInbox'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    Header,
    ModalPage,
    ModuleInbox
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        if (this.$route.params.groupId) {
          this.showModalPage = true
        } else {
          this.showModalPage = false
        }
      }
    }
  },
  data () {
    return {
      showModalPage: false,
      headerLeftIcon: {
        icon: 'arrow_back_ios'
      },
      headerRightIcon: {
        icon: ''
      },
      modalPageContentId: 'chat'
    }
  },
  created () {
    if (this.$route.params.groupId) {
      this.showModalPage = true
    } else {
      this.showModalPage = false
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    },
    closeModalPage () {
      this.showModalPage = false
    },
    headerOnLeft () {
      var path = (this.$route.name === 'inbox') ? '/inbox' : '/client_inbox'
      this.$router.push(path)
    }
  }
}
</script>
