<template lang="pug">
  div.wrap-module-inbox
    div.wrap-h2.f.fm.mb12.pt20
      h2 インボックス
    div(v-if="groups && groups[0]").wrap-cards
      ItemInboxCard(v-for="item in groups" :content="item")
    div(v-else).no-chat.f.fh
      span メッセージはありません。
</template>

<style lang="scss" scoped>
.wrap-module-inbox {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 48px;
  h2 {
    color: #2a2a2a;
    font-size: 16px;
    border-bottom: solid #2a2a2a 1px;
  }
  .wrap-cards {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .no-chat {
    height: 80vh;
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import ItemInboxCard from '@/components/item/ItemInboxCard'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemInboxCard
  },
  props: {
  },
  data () {
    return {
      groups: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.groups = await db.collection('GROUPS')
      .where('member', 'array-contains', this.uid)
      .orderBy('updated', 'desc')
      .onSnapshot(this.getGroups)
  },
  methods: {
    getGroups (q) {
      this.groups = q.docs.map(d => {
        var data = d.data()
        data.id = d.id
        return data
      })
    }
  }
}
</script>
